import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Navigate, useNavigate } from "react-router";
import { anonymousUser } from "../../helper/service";
import LoaderComponent from "../loader";
import { useDispatch } from "react-redux";
import { getVerifyAuth } from "../../store/thunk/authThunk";

function PrivateRoute(props: any) {
  const { children } = props;
  const { logout } = useAuth0();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (window.self !== window.top) {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');
      if (token) {
        localStorage.setItem('iframeAccessToken', token);
      }
    }
  }, []);

  const accessToken = localStorage.getItem("accessToken")
    ? localStorage.getItem("accessToken")
    : null;

  const userData: any = localStorage.getItem("userData")
    ? localStorage.getItem("userData")
    : null;

  const localData: any = localStorage.getItem("bootstrapData");
  const bootstrapData: any = localData ? JSON.parse(localData) : null;

  const localIsBootstrapCalled: any = localStorage.getItem("isBootstrapCalled");
  const isBootstrapCalled: any = localIsBootstrapCalled
    ? JSON.parse(localIsBootstrapCalled)
    : null;

  useEffect(() => {
    if (!anonymousUser() && bootstrapData === null && accessToken) {
      setIsLoading(true);
      dispatch(getVerifyAuth(accessToken)).then((res: any) => {
        if (res?.payload?.status === 200) {
          if (!res?.payload?.data?.isVerified) {
            navigate("/verify");
          } else {
            let tempUserData = { ...JSON.parse(userData) };
            tempUserData["email_verified"] = true;
            localStorage.setItem("userData", JSON.stringify(tempUserData));
          }
        }
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [bootstrapData, accessToken, dispatch, navigate, userData]);

  if (anonymousUser()) {
    return children;
  }

  if (!accessToken) {
    logout({ logoutParams: { returnTo: window.location.origin } });
    return null;
  }

  if (isLoading) {
    return <LoaderComponent />;
  }

  if (bootstrapData && (!bootstrapData?.isVerified || !JSON.parse(userData)?.email_verified)) {
    return <Navigate to="/verify" />;
  }

  return isBootstrapCalled ? (accessToken && userData ? children : <Navigate to="/home" />) : <LoaderComponent />;
}

export default PrivateRoute;
