import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  flowDataLoading: false,
  loading: false,
  uiFormPayloadLoading: false,
  paymentLoading: false,
  uploadLoading: false,
  downloadLoading: false,
  isCaseTypeSubmitted: false
};

const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    notificationFail: (state, action) => ({
      ...state,
      status: false,
      message: action.payload,
    }),
    notificationSuccess: (state, action) => ({
      ...state,
      status: true,
      message: action.payload,
    }),
    notificationClear: (state) => ({
      ...state,
      message: null,
      status: null,
      type: null,
    }),
    setLoading: (state, action) => ({
      ...state,
      loading: action.payload,
    }),
    setUIFormPayloadLoading: (state, action) => ({
      ...state,
      uiFormPayloadLoading: action.payload,
    }),
    setPaymentLoading: (state, action) => ({
      ...state,
      paymentLoading: action.payload,
    }),
    setDownloadLoading: (state, action) => ({
      ...state,
      downloadLoading: action.payload,
    }),
    setflowDataLoading: (state, action) => ({
      ...state,
      flowDataLoading: action.payload,
    }),
    setUploadLoading: (state, action) => ({
      ...state,
      uploadLoading: action.payload,
    }),
    setIsCaseTypeSubmitted: (state, action) => ({
      ...state,
      isCaseTypeSubmitted: action.payload,
    }),
  },
});

export const {
  notificationFail,
  notificationSuccess,
  notificationClear,
  setLoading,
  setUIFormPayloadLoading,
  setPaymentLoading,
  setDownloadLoading,
  setUploadLoading,
  setflowDataLoading,
  setIsCaseTypeSubmitted
} = notificationSlice.actions;

export default notificationSlice.reducer;
