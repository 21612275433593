import { useAppSelector } from "../../store/store";
import { Box, Grid, Typography } from "@mui/material";
import PayAndDownloadButton from "./payAndDownloadButton";
import SignInSignUpButton from "./signInSignUpButton";
import { anonymousUser } from "../../helper/service";
import BackDropLoader from "../../components/loader/backDropLoader";
import CompletedForm from "../../assets/images/completedForm.svg";
import PaynowEvidence from "../../assets/images/paynowEvidence.svg";
import PaynowCoverLetter from "../../assets/images/paynowCoverLetter.svg";
import PaynowPrintShip from "../../assets/images/print_ship.svg";

const PayAndDownload = () => {
  const { loading } = useAppSelector((state: any) => state.notificationReducer);

  return (
    <div>
      <div className="md:p-6 p-4">
        {anonymousUser() ? (
          <>
            <Box
              className="signin_up_btn_container"
              sx={{
                background: "#eff6fe",
                borderRadius: "12px",
                m: "0 auto",
              }}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <SignInSignUpButton />
            </Box>
          </>
        ) : (
          <Box display={"flex"} alignItems={"center"} flexDirection={"column"}>
            <Typography
              sx={{ textAlign: "center" }}
              style={{
                fontSize: "22px",
                fontWeight: "600",
                marginBottom: "15px",
              }}
            >
              Pay now to download your completed packet!
            </Typography>
            <Typography style={{ fontSize: "22px", fontWeight: "600" }}>
              $349
            </Typography>
            <PayAndDownloadButton isBoth={false} isPaynow={true} />
            <Typography
              sx={{ textAlign: "center" }}
              style={{
                fontSize: "15px",
                fontWeight: "600",
                marginTop: "25px",
                marginBottom: "40px",
              }}
            >
              Just print, sign and ship! Don't worry we will walk you through
              it!
            </Typography>
            <Grid
              container
              spacing={5}
              sx={{ justifyContent: "center", mb: "40px" }}
            >
              <Grid item sm={6} md={3}>
                <Box className="paynow_individaul_section">
                  <Box
                    className="paynow_individual_icon"
                    sx={{ background: "#c23331" }}
                  >
                    <img src={CompletedForm} width="40px" height="40px" />
                  </Box>

                  <h6 style={{ fontWeight: "600", marginBottom: "10px" }}>
                    Completed forms
                  </h6>
                  <Typography
                    className="paynow_individaul_desc"
                    sx={{ maxWidth: { xs: "165px", md: "inherit" } }}
                  >
                    All your forms completed!
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} md={3}>
                <Box className="paynow_individaul_section">
                  <Box
                    className="paynow_individual_icon"
                    sx={{ background: "#a359a0" }}
                  >
                    <img src={PaynowEvidence} width="40px" height="40px" />
                  </Box>
                  <h6 style={{ fontWeight: "600", marginBottom: "10px" }}>
                    Evidence
                  </h6>
                  <Typography
                    className="paynow_individaul_desc"
                    sx={{ maxWidth: { xs: "165px", md: "inherit" } }}
                  >
                    All your evidence organized!
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} md={3}>
                <Box className="paynow_individaul_section">
                  <Box
                    className="paynow_individual_icon"
                    sx={{ background: "#7f95e4" }}
                  >
                    <img src={PaynowCoverLetter} width="40px" height="40px" />
                  </Box>
                  <h6 style={{ fontWeight: "600", marginBottom: "10px" }}>
                    Cover letter
                  </h6>
                  <Typography
                    className="paynow_individaul_desc"
                    sx={{ maxWidth: { xs: "165px", sm: "inherit" } }}
                  >
                    Your cover letter that contains all critical information and
                    we have prepared this for download!
                  </Typography>
                </Box>
              </Grid>
              <Grid item sm={6} md={3}>
                <Box className="paynow_individaul_section">
                  <Box
                    className="paynow_individual_icon"
                    sx={{ background: "#5aa96b" }}
                  >
                    <img src={PaynowPrintShip} width="40px" height="40px" />
                  </Box>
                  <h6 style={{ fontWeight: "600", marginBottom: "10px" }}>
                    Print and Ship
                  </h6>
                  <Typography
                    className="paynow_individaul_desc"
                    sx={{ maxWidth: { xs: "165px", md: "inherit" } }}
                  >
                    We will walk you through step by step on assembling your
                    packet and you can always come back and edit anything!
                  </Typography>
                </Box>
              </Grid>
            </Grid>
            <PayAndDownloadButton isBoth={false} isPaynow={false} />
          </Box>
        )}
      </div>

      {loading && !anonymousUser() && <BackDropLoader />}
    </div>
  );
};

export default PayAndDownload;
