import { useEffect } from "react";
import { Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import Hotjar from "@hotjar/browser";
import mixpanel from "mixpanel-browser";

import Home from "../../pages/home";
import Verify from "../../pages/verify";
import UserForms from "../../pages/userForms";

import LoginComponent from "../../pages/login";
import DisableUser from "../../pages/disableUser";

import PublicRoute from "../../components/PublicRoute";
import StepperComponent from "../../components/stepper";
import PrivateRoute from "../../components/PrivateRoute";
import LayoutComponent from "../../components/layout/layout";
import PublicFormflow from "../../components/public/userForms";
import VerifyLayoutComponent from "../../components/verifyLayout";

import { useAppSelector } from "../../store/store";
import { shouldThirdPartyIntegration } from "../../helper/service";
import IframeListener from "../../pages/IframeListener";

function UserRoutes() {
  const { authConfig } = useAppSelector((state: any) => state.authReducer);
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData") || "{}")
    : null;

  useEffect(() => {
    if (shouldThirdPartyIntegration()) {
      if (authConfig?.hotjarData?.siteId && authConfig?.hotjarData?.version) {
        Hotjar.init(
          authConfig?.hotjarData?.siteId,
          authConfig?.hotjarData?.version,
          {
            debug: true,
          }
        );
      }

      if (authConfig?.mixPanelData?.projectId) {
        mixpanel.init(authConfig?.mixPanelData?.projectId, {
          debug: true, // Enable debugging in development
          track_pageview: true, // Track page views
          persistence: "localStorage", // Choose persistence method
        });

        mixpanel.people.set({
          $email: userData?.email,
        });

        mixpanel.people.set({
          $email: userData?.email,
        });
      }
    }
  }, [authConfig]);

  if (
    authConfig?.authData?.domain !== "" &&
    authConfig?.authData?.clientId !== ""
  ) {
    return (
      <Auth0Provider
        domain={authConfig?.authData?.domain || ""}
        clientId={authConfig?.authData?.clientId || ""}
        useRefreshTokens={true}
        authorizationParams={{
          redirect_uri: window.location.origin,
          scope: "openid profile email read:current_user",
          audience: authConfig?.authData?.audience || "",
        }}
      >
        <BrowserRouter>
          <Routes>
            <Route
              path="/login"
              element={
                <PublicRoute>
                  <LoginComponent />
                </PublicRoute>
              }
            ></Route>
            <Route
              path="/"
              element={
                <PublicRoute>
                  <LoginComponent />
                </PublicRoute>
              }
            ></Route>

            {/* <Route
              path="/users"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <UserTable />
                  </LayoutComponent>
                </PrivateRoute>
              }
            ></Route> */}

            <Route
              path="/verify"
              element={
                <VerifyLayoutComponent>
                  <Verify />
                </VerifyLayoutComponent>
              }
            ></Route>
            <Route path="/iframe-listener" element={<IframeListener />} />
            <Route
              path="/home"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <Home />
                  </LayoutComponent>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/userForm"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <UserForms />
                  </LayoutComponent>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/eligibility-check/:section-id"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <StepperComponent />
                  </LayoutComponent>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/eligibility-check/:index"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <StepperComponent />
                  </LayoutComponent>
                </PrivateRoute>
              }
            ></Route>
            <Route
              path="/disable-user"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <DisableUser />
                  </LayoutComponent>
                </PrivateRoute>
              }
            ></Route>

            {/* <Route
              path="/form"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <Formflow />
                  </LayoutComponent>
                </PrivateRoute>
              }
            /> 
            <Route
              path="/refresh"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <Refreshflow />
                  </LayoutComponent>
                </PrivateRoute>
              }
            /> 
            <Route
              path="/add-new-form"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <AddNewForm />
                  </LayoutComponent>
                </PrivateRoute>
              }
            />
              <Route
              path="/b2b-entities"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <B2BEntities />
                  </LayoutComponent>
                </PrivateRoute>
              }
            />
            <Route
              path="/form_emulation"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <FormEmulation />
                  </LayoutComponent>
                </PrivateRoute>
              }
            /> */}
            <Route
              path="/public/userForm/:formId"
              element={
                <PrivateRoute>
                  <PublicFormflow />
                </PrivateRoute>
              }
            />
            {/* <Route
              path="/admin"
              element={
                <PrivateRoute>
                  <LayoutComponent>
                    <AdminDashboard />
                  </LayoutComponent>
                </PrivateRoute>
              }
            /> */}
            <Route path="*" element={<Navigate to="/" replace />} />
          </Routes>
        </BrowserRouter>
      </Auth0Provider>
    );
  } else {
    return null;
  }
}

export default UserRoutes;
