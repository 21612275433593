import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  setLoading,
  notificationFail,
  notificationSuccess,
} from "../slices/notificationSlice";
import { setUsersList } from "../slices/userSlice";
import apiClient from "../../config/apiClient";
import envConfig from "../../config/config";
import {
  setAuthConfig,
  setUiIncomeLimit,
  setVerifyAuth,
} from "../slices/authSlice";
import dotnetApiClient from "../../config/dotnetApiClient";
import { handleLogout } from "../../helper/service";

export const loginAdmin = createAsyncThunk(
  "loginAdmin",
  async (_request, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiClient().post(`users`, _request);
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setUsersList(response?.data));
        notificationSuccess(response?.data?.message);
      } else {
        dispatch(notificationSuccess(response?.data?.message));
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const authLogin: any = createAsyncThunk(
  "authLogin",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiClient().get(`auth/?code=${_request}`);
      dispatch(setLoading(false));
      if (response?.data) {
        dispatch(setUsersList(response?.data));
        notificationSuccess(response?.data?.message);
        localStorage.setItem("userId", response?.data?.data?.id);
        localStorage.setItem("userData", JSON.stringify(response?.data?.data));
        localStorage.setItem("accessToken", response?.data?.data?.access_token);
        if (response?.data?.data.user_type == 1) {
          localStorage.setItem("role", "superAdmin");
          window.location.replace("/users");
        } else {
          window.location.replace("/home");
        }
        // notificationSuccess;
        // _request?.navigate("/users");
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const authLogout: any = createAsyncThunk(
  "authLogout",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response = await apiClient().get(`auth/logout`);
      await apiClient().get(`auth/logout`);
      dispatch(setLoading(false));
      if (response?.data) {
        localStorage.removeItem("role");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("userId");
        localStorage.removeItem("userData");
        localStorage.removeItem("unSavedFormValues");
        localStorage.removeItem("updatedFormValues");
        window.location.href = `https://${process.env.REACT_APP_AUTH_DOMAIN}/oidc/logout?id_token_hint=${response?.data?.id_token}&post_logout_redirect_uri=${process.env.REACT_APP_REDIRECT_URI}`;
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// ---------- bootstrap GET call
export const getAuthConfig: any = createAsyncThunk(
  "getAuthConfig",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await dotnetApiClient(false, false).get(
        `/bootstrap`
      );

      const uiFlowJsonId = localStorage.getItem("uiFlowJsonId");

      dispatch(setLoading(false));

      if (response?.data) {
        if (uiFlowJsonId) {
          localStorage.setItem("uiFlowJsonId", uiFlowJsonId);
        }
        dispatch(setAuthConfig(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

export const getUiIncomeLimit: any = createAsyncThunk(
  "getUiIncomeLimit",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await dotnetApiClient(false, false).get(
        `/UiIncomeLimit`
      );
      dispatch(setLoading(false));

      if (response?.data) {
        dispatch(setUiIncomeLimit(response?.data));
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// ---------- bootstrap POST call
export const getVerifyAuth: any = createAsyncThunk(
  "getVerifyAuth",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await dotnetApiClient().post(`/bootstrap`);

      if (response?.data) {
        localStorage.setItem("userType", response?.data?.userType);
        localStorage.setItem("bootstrapData", JSON.stringify(response?.data));
        localStorage.setItem("isBootstrapCalled", "true");

        dispatch(setVerifyAuth(response?.data));

        return response;
      } else {
        dispatch(
          notificationFail(response?.data?.message || "Something went wrong")
        );
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(notificationFail("Something went wrong"));
    } finally {
      dispatch(setLoading(false));
    }
  }
);

export const resendVerifyEmailLink: any = createAsyncThunk(
  "resendVerificationMail",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await dotnetApiClient().post(
        `/bootstrap/sendVerificationEmail`
      );

      if (response?.status === 200) {
        dispatch(setLoading(false));
        dispatch(
          notificationSuccess(
            "Verification link has been sent successfully to your inbox."
          )
        );
        return response;
      }
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);

// ---------- bootstrap email verification GET call
export const getVerifyEmail: any = createAsyncThunk(
  "getEmailVerified",
  async (_request: any, { dispatch }) => {
    try {
      dispatch(setLoading(true));
      const response: any = await dotnetApiClient().get(
        `/bootstrap/emailVerified`
      );
      dispatch(setLoading(false));

      return response;
    } catch (error: any) {
      if (error?.response?.status === 401 || error?.response?.status === 406) {
        handleLogout();
      }
      dispatch(setLoading(false));
      dispatch(notificationFail("Something went wrong"));
    }
  }
);
